<template>
  <div class="payments">
    <!-- 面包屑 -->
    <el-breadcrumb
      class="defauult-breadcrumb"
      separator-class="el-icon-arrow-right"
    >
      <el-breadcrumb-item :to="{ path: '/product' }">
        产品中心
      </el-breadcrumb-item>
      <el-breadcrumb-item
        :to="{ path: `/product/detail?id=${this.$route.query.id}` }"
      >
        产品详情
      </el-breadcrumb-item>
      <el-breadcrumb-item>购买</el-breadcrumb-item>
    </el-breadcrumb>

    <div class="payments_order">
      <!-- 订单进度状态 -->
      <div class="order_status">
        <!-- 支付进度 -->
        <div class="step">
          <div class="play">
            <p class="step_bg">1</p>
            <p>订单支付</p>
          </div>
          <div class="line">
            <p></p>
          </div>
          <div class="play_success">
            <p :class="{ step_bg: step_value == 2 || step_value == 3 }">2</p>
            <p v-show="step_value != 2 && step_value != 3" class="tip">
              支付结果
            </p>
            <p v-show="step_value == 2" class="tip">支付成功</p>
            <p v-show="step_value == 3" class="tip">支付失败</p>
          </div>
        </div>
        <!--支付状态 -->
        <div class="result" v-if="step_value == 2">
          <i
            style="font-size: 50px; vertical-align: middle"
            class="el-icon-success"
          ></i>
          <span class="succeed" style="vertical-align: middle">支付成功</span>
          <el-button
            type="text"
            class="btn"
            @click="$router.push(`/personal-center/purchase/place-order`)"
          >
            返回订单页
          </el-button>
        </div>
        <!-- 支付失败 -->
        <div class="result" v-else-if="step_value == 3">
          <i style="font-size: 50px" class="el-icon-error"></i>
          <span class="succeed">支付失败</span>
          <div style="text-aling: center">
            <el-button type="text" class="btn">
              账户余额不足，联系管理员充值
            </el-button>
          </div>
        </div>
      </div>
      <!-- 主体 -->
      <div class="order_container">
        <!-- 收货人信息 -->
        <div>
          <span class="title">收货人信息</span>
          <div
            class="consignee"
            v-for="(item, i) in address_list"
            :key="i"
            :class="{
              'consignee-select': item.id == address_id,
              'consignee-hide': i > 0 && !isAddressShow
            }"
            @click="setAddress(i)"
          >
            <div class="arrows" v-show="item.id == address_id"></div>
            <div class="details">
              <span style="margin-right: 10px">{{ item.name }}</span>
              <span style="margin-right: 10px">{{
                item.address.replace(/,/g, "")
              }}</span>
              <span style="margin-right: 10px">{{ item.addressDetail }}</span>
              <span style="margin-right: 10px">{{
                $utils.formattedPhone(item.phone)
              }}</span>
            </div>
            <div class="tacitly" size="small" v-show="item.isDefault == 1">
              默认地址
            </div>
            <!-- <div class="tacitly" size="small" v-show="item.isDefault == 0">
              添加地址
            </div> -->
            <div
              class="tacitly-opt"
              size="small"
              v-show="step_value != 2 && step_value != 3 && item.isDefault != 1"
              @click.stop="openVisibleTip(item.id)"
            >
              设为默认地址
            </div>
            <div class="operation" v-show="step_value != 2 && step_value != 3">
              <span class="operation-item" @click.stop="updateAddress(item)">
                编辑
              </span>
              <span
                class="operation-item operation-item-del"
                v-show="item.id != address_id"
                @click.stop="openVisibleDel(item.id)"
              >
                删除
              </span>
            </div>
          </div>
          <div
            class="consignee consignee-add"
            :class="{
              'consignee-hide': address_list.length > 0 && !isAddressShow
            }"
            @click="updateAddress(null)"
            v-show="step_value != 2 && step_value != 3"
          >
            + 新增地址
          </div>
          <!-- <div
            class="open-opt"
            v-show="!isAddressShow"
            @click="isAddressShow = true"
          >
            展开 <span class="iconfont icon-gengduo-copy"></span>
          </div> -->
          <div
            v-show="address_list.length > 0"
            class="hide-opt"
            @click="isAddressShow = !isAddressShow"
          >
            {{ isAddressShow ? "收起" : "展开" }}
            <span class="iconfont icon-gengduo-copy"></span>
            <!-- <el-button
              class="btn"
              type="primary"
              plain
              @click.stop="updateAddress()"
              style="float:right;"
              >新增收货地址</el-button
            > -->
          </div>
          <!-- <el-card class="box-card">
            <div v-for="o in 4" :key="o" class="text item">
              {{ "列表内容 " + o }}
            </div>
          </el-card> -->
        </div>
        <!-- 支付方式 -->
        <div>
          <div class="pattern">
            <div class="title">支付方式</div>
            <div class="surplus">
              <button class="purchase">
                采购结余
                <span style="color: rgb(255, 6, 6)"
                  ><span style="font-size: 12px;">￥</span
                  >{{ $utils.formatMoney(Number(user.procurement)) }}</span
                >
              </button>
            </div>
          </div>
        </div>
        <div class="install-body" v-if="list">
          <div class="install-body-header">
            <div class="order" style="font-size: 20px;">订单详细信息</div>
          </div>
          <div class="install-body-list">
            <table>
              <thead>
                <tr class="list-header">
                  <th class="header-item item-385">产品信息</th>
                  <th class="header-item item-385">数量</th>
                  <th class="header-item item-225">需付金额</th>
                  <th class="header-item item-135">返现金额</th>
                </tr>
              </thead>
            </table>
            <table>
              <tbody class="list-line-item">
                <tr class="sep-row">
                  <td colspan="4"></td>
                </tr>
                <tr>
                  <td colspan="4" class="line-item-colspan">
                    <span class="time"> 发布日期：{{ list.createDate }} </span>
                  </td>
                </tr>
                <tr>
                  <td class="line-item item-385">
                    <div class="img-or-name">
                      <img :src="$utils.picturePrefix(list.image)" />
                      <span>
                        <p>{{ list.name }}</p>
                        <p>{{ list.typeName }}</p>
                      </span>
                    </div>
                  </td>
                  <td class="line-item item-385">
                    <div class="order-type">
                      {{ query.num }}
                    </div>
                  </td>
                  <td class="line-item item-225">
                    ￥{{
                      $utils.formatMoney((Number(list.price) - Number(backMoney)) * Number(query.num))
                    }}
                  </td>
                  <td class="line-item item-135">
                    ￥{{ backMoney * Number(query.num) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div>
          <div class="pattern">
            <div class="title">订单备注</div>
            <div class="surplus" style="margin-top: 10px">
              <el-input :rows="4" placeholder="请输入" v-model="desc">
              </el-input>
            </div>
          </div>
        </div>
        <div
          class="button"
          v-show="step_value != 2 && step_value != 3 && orderType"
        >
          <el-button type="primary" @click="saveOrder">提交订单</el-button>
          <el-button type="text" @click="dialogVisible = true"></el-button>
        </div>
      </div>
      <!-- 更改地址弹框 -->
      <tip-modal
        :visible="visibleTip"
        @close="handleClose"
        @ok="consigneeDefault"
      >
        <span>确定设为默认地址吗？</span>
      </tip-modal>
      <tip-modal
        :visible="visibleDel"
        @close="handleClose"
        @ok="consigneeDelete"
      >
        <span>确定删除该地址吗？</span>
      </tip-modal>
      <operation ref="operation"></operation>
    </div>
  </div>
</template>

<script>
// import Breadcrumb from "@/components/Breadcrumb";
// import { session } from "@/utils/sessions";
import TipModal from "@/components/TipModal";
import operation from "../../../personalCenter/purchase/shippingAddress/operation";
// import mixin from "./mixin";
export default {
  // mixins: [mixin],
  components: { TipModal, operation },
  data() {
    return {
      visibleTip: false,
      visibleDel: false,
      num: 1,
      isAddressShow: false,
      desc: "",
      // 派单首页的数据
      params: {},
      list: {},
      query: {},
      step_value: 1,
      visible: false,
      address_id: 0,
      address_list: [],
      orderType: true,
      default_address: {
        address: "",
        name: "",
        phone: ""
      },
      user: {},
      backMoney: 0
      // procurement: ""
    };
  },

  watch: {
    "$store.getters.user": {
      handler(val) {
        this.user = val;
      },
      deep: true
    }
  },

  computed: {
    userId() {
      return this.$store.getters.userId;
    }
    // user() {
    //   return this.$store.getters.user;
    // }
  },
  created() {
    console.log(this.user.procurement);

    this.query = this.$route.query;
    this.query_getlist();
    this.getConsigneeList();
    this.getOrderMoney();
  },
  mounted() {
    this.user = this.$store.getters.user;
  },

  methods: {
    getOrderMoney() {
      this.$api
        .getOrderMoney({
          productId: this.$route.query.id,
          userId: this.userId
        })
        .then(res => {
          console.log(res);
          this.backMoney = res.data.backMoney;
        });
    },
    // 提交订单按钮
    saveOrder() {
      if (!this.address_id) {
        this.$message({
          type: "error",
          message: "请选择收货地址!"
        });
        return;
      }
      let price =
        (Number(this.list.price) - Number(this.backMoney)) *
        Number(this.query.num);
      if (this.user.procurement >= price) {
        this.$api
          .addOrder({
            customerId: this.userId,
            productId: this.list.id,
            money: price,
            num: Number(this.query.num),
            consigneeId: this.address_id,
            remark: this.desc
          })
          .then(res => {
            let { code, msg } = res;
            if (code == 200) {
              // sessionStorage.setItem("orderType", 1);
              this.step_value = 2;
              document.getElementById("app").scrollTop = 0;
              this.$message({
                type: "success",
                message: msg
              });
              this.$store.dispatch("getUser");
              this.query_getlist();
            }
          });
        //
      } else {
        this.step_value = 3;
        this.$message({
          type: "error",
          message: "账户余额不足，联系管理员充值!"
        });
      }
    },

    /**
     * 更新地址
     */
    updateAddress(item) {
      if (item) {
        this.$refs.operation.init(item.id, { ...item });
      } else {
        if (this.address_list.length >= 10) {
          this.$message.error(`最多只能新建10个地址`);
        } else {
          this.$refs.operation.init();
        }
      }
    },

    /**
     * 关闭弹框
     */
    handleClose() {
      this.visibleTip = false;
      this.visibleDel = false;
    },

    /**
     * 打开删除二次确认弹框
     */
    openVisibleDel(id) {
      this.aId = id;
      this.visibleDel = true;
    },

    /**
     * 打开二次确认弹框
     */
    openVisibleTip(id) {
      this.aId = id;
      this.visibleTip = true;
    },

    /**
     * 设置为默认地址
     */
    consigneeDefault() {
      // consigneeDefault
      let params = {
        id: this.aId
      };
      const loading = this.$loading({
        lock: true,
        text: "设置中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .consigneeDefault(params)
        .then(() => {
          this.$message({
            message: "恭喜您，设置成功！",
            type: "success"
          });
          this.visibleTip = false;
          this.getConsigneeList();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 删除地址
     */
    consigneeDelete() {
      let params = {
        id: this.aId
      };
      const loading = this.$loading({
        lock: true,
        text: "删除中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .consigneeDelete(params)
        .then(() => {
          this.$message({
            message: "恭喜您，删除成功！",
            type: "success"
          });
          this.visibleDel = false;
          this.getConsigneeList();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    // 售卖详情
    query_getOrderDetail() {
      this.list = null;
      this.$api.getOrderDetail(this.$route.query.id).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.params = data;
          this.params.image = this.params.product.banner;
          this.params.name = this.params.product.name;
          this.params.createDate = this.params.product.createDate;
        }
      });
    },

    //  产品详情
    query_getlist() {
      this.params = null;
      this.$api.getProductDetail(this.$route.query.id).then(res => {
        let { code, data } = res;
        if (code == 200) {
          data.banner = data.banner.split(",");
          this.list = data;
          // this.bannerDealWith(0);
        }
      });
    },

    // 更改地址弹框确认按钮
    save() {
      this.visible = false;
      this.address_list.forEach(e => {
        if (e.id == this.address_id) {
          this.default_address = e;
        }
      });
    },

    //  获取地址列表
    getConsigneeList() {
      this.$api
        .getConsigneeList({ userId: this.$store.getters.userId })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            data.sort(function(a, b) {
              return b.isDefault - a.isDefault;
            });
            this.address_list = data;
            this.address_id = data[0].id;
          }
        });
    },
    /**
     * 设置收货地址
     */
    setAddress(index) {
      let addressList = [...this.address_list];
      // 选中的地址
      let selAddress = addressList[index];
      if (
        this.address_id != selAddress.id &&
        this.step_value != 2 &&
        this.step_value != 3
      ) {
        this.address_id = selAddress.id;
        addressList.splice(index, 1);
        addressList.splice(0, 0, selAddress);

        const loading = this.$loading({
          lock: true,
          text: "",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
        });
        setTimeout(() => {
          this.address_list = addressList;
          this.isAddressShow = false;
          loading.close();
        }, 100);
      }
    }
  }
};
</script>
<style lang="less">
@import "./index.less";
</style>
