<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 20:39:21
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-10-11 14:07:56
-->
<template>
  <div>
    <modal :visible="visible" :title="title" @close="handleClose">
      <div class="modal-body">
        <el-form class="modal-form" ref="ruleForm" :model="form" :rules="rules">
          <el-form-item label="收货人" prop="name">
            <el-input v-model="form.name" placeholder="请输入收货人"></el-input>
          </el-form-item>
          <el-form-item label="所在区域" prop="address">
            <el-cascader
              v-model="form.address"
              filterable
              :options="getAddress"
              style="width: 430px;"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="详细地址" prop="addressDetail">
            <el-input
              v-model="form.addressDetail"
              placeholder="请输入详细地址"
            ></el-input>
          </el-form-item>
          <el-form-item label="手机号码" prop="phone">
            <el-input
              v-model="form.phone"
              placeholder="请输入手机号码"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="onSubmit">
          确 定
        </el-button>
      </span>
    </modal>
  </div>
</template>

<script>
import Modal from "@/components/Modal";

export default {
  components: {
    Modal
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        let reg = /^1[3-9][0-9]\d{8}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    return {
      visible: false,
      title: "新增地址",
      form: {
        name: "",
        address: ["340000", "340100", "340104"],
        addressDetail: "",
        phone: ""
      },
      aId: null,
      rules: {
        name: [{ required: true, message: "收货人不可为空", trigger: "blur" }],
        address: [
          { required: true, message: "请选择所在区域", trigger: "blur" }
        ],
        addressDetail: [
          { required: true, message: "详细地址不可以为空", trigger: "blur" }
        ],
        phone: [{ validator: checkPhone, trigger: "blur" }]
      }
    };
  },
  computed: {
    getAddress() {
      return this.$store.getters.address || [];
    },
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {},
  methods: {
    init(id, form) {
      this.aId = id;
      if (id == null) {
        this.title = "新增收货地址";
        this.form = {
          name: "",
          address: ["340000", "340100", "340104"],
          addressDetail: "",
          phone: ""
        };
      } else {
        this.title = "修改收货地址";
        let area = [];
        let address = form.address.split(",");
        let addressArr = [...this.getAddress];
        address.map(item => {
          let addressJson = addressArr.find(nape => {
            return nape.label == item;
          });
          if (addressJson) {
            area.push(addressJson.value);
            if (addressJson.children) {
              addressArr = [...addressJson.children];
            }
          }
        });
        form.address = area;
        this.form = form;
      }
      this.visible = true;
    },
    handleClose() {},
    onSubmit() {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let form = { ...this.form };
          let addressArr = [...this.getAddress];
          let area = [...this.form.address];
          let addressStr = "";
          let i = 0;
          area.map(item => {
            let addressJson = addressArr.find(nape => {
              return nape.value == item;
            });
            if (addressJson) {
              if (i > 0) {
                addressStr += ",";
              }
              addressStr += addressJson.label;
              i++;
              if (addressJson.children) {
                addressArr = [...addressJson.children];
              }
            }
          });
          form.area = [...area].toString();
          form.address = addressStr;
          if (this.aId) {
            this.consigneeEdit(form);
          } else {
            this.consigneeAdd(form);
          }
          this.$parent.getConsigneeList();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    /**
     * 申请退款/退货/换货
     */
    consigneeAdd(form) {
      let params = {
        customerId: this.userId,
        ...form
      };
      const loading = this.$loading({
        lock: true,
        text: "新增中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .consigneeAdd(params)
        .then(() => {
          this.$message({
            message: "恭喜您，新增收货地址成功！",
            type: "success"
          });
          this.visible = false;
          this.$parent.getConsigneeList();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 申请退款/退货/换货
     */
    consigneeEdit(form) {
      let params = {
        id: this.aId,
        customerId: this.userId,
        ...form
      };
      const loading = this.$loading({
        lock: true,
        text: "修改中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .consigneeEdit(params)
        .then(() => {
          this.$message({
            message: "恭喜您，修改收货地址成功！",
            type: "success"
          });
          this.visible = false;
          this.$parent.getConsigneeList();
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    }
  }
};
</script>

<style lang="less" scoped></style>
